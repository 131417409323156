import React, { useState, useEffect } from 'react';
import { useLogin } from 'react-admin';

import { Box, Button, Card, CardActions, CircularProgress } from '@mui/material';

import background from './assets/velious.png';

const Login = () => {
  const login = useLogin();
  const [loading, setLoading] = useState(false);
  const [loginClicked, setLoginClicked] = useState(false);

  const cleanup = () => {
    // Remove the ?code&state from the URL
    window.history.replaceState({}, window.document.title, window.location.origin);
  };

  useEffect(() => {
    const { searchParams } = new URL(window.location.href);
    const code = searchParams.get('code');

    // If code is present, we came back from the provider
    if (code) {
      setLoading(true);
      cleanup();
      login({ code });
    }
  }, [login]);

  const handleLogin = () => {
    setLoginClicked(true);
    login();
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', minHeight: '100vh', background: '#fcfcfe' }}>
        <CircularProgress sx={{ marginTop: 'auto', marginBottom: 'auto', marginLeft: 'auto', marginRight: 'auto' }} />
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          alignItems: 'center',
          justifyContent: 'flex-start',
          background: `url(${background})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Card sx={{ minWidth: 300, marginTop: 'auto', marginBottom: 'auto', opacity: 1 }}>
          <Box
            sx={{
              marginTop: '1em',
              display: 'flex',
              justifyContent: 'center',
              color: theme => theme.palette.grey[500],
            }}
          ></Box>

          <CardActions sx={{ padding: '0 1em 1em 1em' }}>
            <Button variant="contained" type="submit" color="secondary" disabled={loading} onClick={handleLogin} fullWidth>
              {loginClicked && <CircularProgress size={25} thickness={2} />}
              {!loginClicked && <span>Login with Discord</span>}
            </Button>
          </CardActions>
        </Card>
      </Box>
    );
  }
};

export default Login;
