import * as React from 'react';
import { useRecordContext, useGetManyReference, Loading, ReferenceField, ReferenceManyField, Datagrid, TextField, NumberField, BooleanField } from 'react-admin';

const ZoneTargetTable = () => {
  const record = useRecordContext();

  // fetch all tods related to the current target
  const { data, isLoading, error } = useGetManyReference('targets', {
    target: 'zoneId',
    id: record.id,
    pagination: { page: 1, perPage: 10 },
    sort: { field: 'name', order: 'ASC' },
  });

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <p>Error loading Target data</p>;
  }

  if (data.length === 0) {
    return <p>No Targets found</p>;
  }

  return (
    <ReferenceManyField label="Targets" reference="targets" target="zoneId">
      <Datagrid rowClick="show" bulkActionButtons={false} sx={{ ml: -2 }}>
        <TextField source="name" />
        <ReferenceField link={false} source="typeId" label="Type" reference="target-types" sort={{ field: 'type', order: 'ASC' }}>
          <TextField source="type" />
        </ReferenceField>
        <NumberField source="spawnTime" label="Spawn Time" />
        <NumberField source="spawnVariance" label="Spawn Variance" />
        <BooleanField source="showTimer" label="Show Timer" />
      </Datagrid>
    </ReferenceManyField>
  );
};

export default ZoneTargetTable;
