import { FilterList, FilterListItem } from 'react-admin';
import { Card, CardContent } from '@mui/material';

export const TargetFilterSiderbar = () => (
  <Card sx={{ order: 1, ml: 2, mt: 8, mb: 6, width: 200, height: 260 }}>
    <CardContent>
      <FilterList label="Target Type">
        <FilterListItem label="Major" value={{ typeId: 2 }} />
        <FilterListItem label="Minor" value={{ typeId: 1 }} />
        <FilterListItem label="Ground Spawn" value={{ typeId: 3 }} />
      </FilterList>
      <FilterList label="Timer Visible">
        <FilterListItem label="True" value={{ showTimer: 1 }} />
        <FilterListItem label="False" value={{ showTimer: 0 }} />
      </FilterList>
    </CardContent>
  </Card>
);
