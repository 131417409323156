import icon from '@mui/icons-material/AccountBalance';
import list from './GuildBankList';
import create from './GuildBankImport';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  icon: icon,
  list: list,
  create: create,
  options: { label: 'Guild Bank' },
};
