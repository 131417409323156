import React from 'react';
import { Admin, Resource, CustomRoutes, memoryStore, defaultTheme } from 'react-admin';
import { Route } from 'react-router-dom';
// import restProvider from 'ra-data-simple-rest';
import authProvider from './authProvider';
import dataProvider from './dataProvider';

import Dashboard from './components/dashboard';
import MyLayout from './layout/Layout';
import Login from './layout/Login';
// import theme from './layout/theme';

// Load Resources
import Zones from './components/zones';
import Targets from './components/targets';
import SharedCharacters from './components/shared-characters';
import GuildBank from './components/guild-bank';

const darkTheme = { ...defaultTheme, palette: { mode: 'dark' } };

function App() {
  return (
    //dataProvider={new restProvider(process.env.REACT_APP_BACKEND_URL)}
    <Admin theme={darkTheme} layout={MyLayout} authProvider={authProvider} dataProvider={dataProvider} loginPage={Login} store={memoryStore()} disableTelemetry requireAuth>
      <CustomRoutes>
        <Route path="/" element={<Dashboard />} />
      </CustomRoutes>

      {permissions => (
        <>
          {/* Restrict Access to Officers */}
          {permissions.includes('leadership') ? <Resource name="zones" {...Zones} /> : null}
          {permissions.includes('leadership') ? <Resource name="targets" {...Targets} /> : null}
          {permissions.includes('leadership') ? <Resource name="shared-characters" {...SharedCharacters} /> : null}

          {/* Restrict Access to Bankers */}
          {permissions.includes('banker') ? <Resource name="guild-bank" {...GuildBank} /> : null}
        </>
      )}
    </Admin>
  );
}

export default App;
