import * as React from 'react';

import {
  useRedirect,
  useNotify,
  useRefresh,
  required,
  Edit,
  NumberInput,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  ShowButton,
  NullableBooleanInput,
  TabbedShowLayout,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin';
import { Box, Card, Grid, Typography, Alert, AlertTitle } from '@mui/material';

const TargetEdit = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const onSuccess = data => {
    notify(
      <Alert severity="success" color="success" variant="standard">
        <AlertTitle>Record Updated</AlertTitle> {data.name} updated successfully
      </Alert>,
      { anchorOrigin: { vertical: 'top', horizontal: 'right' } }
    );
    redirect(`/targets/${data.id}/show`);
    refresh();
  };

  return (
    <Box maxWidth="50em">
      <Edit component="div" mutationMode="pessimistic" mutationOptions={{ onSuccess }} actions={<></>} sx={{ mt: 2 }}>
        <EditForm />
      </Edit>
    </Box>
  );
};

const EditForm = () => {
  return (
    <Card>
      <SimpleForm>
        <Grid container spacing={1}>
          <Grid item xs={11}>
            <Typography variant="h6" gutterBottom align="left">
              Details
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <ShowButton />
          </Grid>
          <Grid item xs={6}>
            <TextInput source="name" label="Target Name" variant="standard" fullWidth required />
          </Grid>
          <Grid item xs={6}>
            <ReferenceInput source="zoneId" label="Zone Name" reference="zones" sort={{ field: 'name', order: 'ASC' }}>
              <AutocompleteInput variant="standard" validate={required()} />
            </ReferenceInput>
          </Grid>
          <Grid item xs={6}>
            <ReferenceInput source="typeId" label="Type" reference="target-types" sort={{ field: 'type', order: 'ASC' }}>
              <SelectInput variant="standard" optionText="type" validate={required()} fullWidth />
            </ReferenceInput>
          </Grid>
          <Grid item xs={3}>
            <NumberInput source="spawnTime" variant="standard" label="Spawn Time (Hours)" />
          </Grid>
          <Grid item xs={3}>
            <NumberInput source="spawnVariance" variant="standard" label="Spawn Time Variance (Hours)" />
          </Grid>
          <Grid item xs={3}>
            <NullableBooleanInput source="showTimer" label="Show Timer" variant="standard" validate={required()} fullWidth />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <TabbedShowLayout>
            <TabbedShowLayout.Tab label="Aliases">
              <ArrayInput source="aliases" label="" sx={{ ml: -1 }}>
                <SimpleFormIterator
                  disableClear
                  disableReordering
                  getItemLabel={index => `#${index + 1}`}
                  sx={{
                    '& .RaSimpleFormIterator-line': {
                      borderBottomWidth: 0,
                    },
                  }}
                  inline
                >
                  <TextInput label="Alias" variant="standard" helperText={false} required />
                </SimpleFormIterator>
              </ArrayInput>
            </TabbedShowLayout.Tab>
          </TabbedShowLayout>
        </Grid>
      </SimpleForm>
    </Card>
  );
};
export default TargetEdit;
