import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Logo from './Logo';

import { AppBar } from 'react-admin';

const MyAppBar = props => {
  return (
    <AppBar {...props} elevation={1}>
      <Typography
        variant="h6"
        color="inherit"
        id="react-admin-title"
        sx={{
          flex: 1,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
      />
      <Logo />
      <Box component="span" sx={{ flex: 1 }} />
    </AppBar>
  );
};
export default MyAppBar;
