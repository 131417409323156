import * as React from 'react';

import { useRedirect, useRefresh, ShowButton, Edit, SimpleForm, TextInput, TabbedShowLayout, ArrayInput, SimpleFormIterator, useNotify } from 'react-admin';
import { Alert, AlertTitle, Box, Card, Grid, Typography } from '@mui/material';

const ZoneEdit = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const onSuccess = data => {
    notify(
      <Alert severity="success" color="success" variant="standard">
        <AlertTitle>Record Updated</AlertTitle> {data.name} updated successfully
      </Alert>,
      { anchorOrigin: { vertical: 'top', horizontal: 'right' } }
    );
    redirect(`/zones`);
    refresh();
  };

  return (
    <Edit component="div" mutationMode="pessimistic" mutationOptions={{ onSuccess }} actions={<></>} sx={{ mt: 2 }}>
      <EditForm />
    </Edit>
  );
};

const EditForm = () => {
  return (
    <Box maxWidth="50em">
      <Card>
        <SimpleForm>
          <Grid container spacing={1}>
            <Grid item xs={11}>
              <Typography variant="h6" gutterBottom align="left">
                Details
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <ShowButton />
            </Grid>
            <Grid item xs={8}>
              <TextInput source="name" label="Zone Name" variant="standard" fullWidth />
            </Grid>
            <Grid item xs={4}>
              <TextInput source="nameShort" label="Short Name" variant="standard" fullWidth />
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
          <Grid container spacing={1}>
            <TabbedShowLayout>
              <TabbedShowLayout.Tab label="Aliases">
                <ArrayInput source="aliases" label="" sx={{ ml: -1 }}>
                  <SimpleFormIterator
                    disableClear
                    disableReordering
                    getItemLabel={index => `#${index + 1}`}
                    sx={{
                      '& .RaSimpleFormIterator-line': {
                        borderBottomWidth: 0,
                      },
                    }}
                    inline
                  >
                    <TextInput label="Alias" variant="standard" helperText={false} required />
                  </SimpleFormIterator>
                </ArrayInput>
              </TabbedShowLayout.Tab>
            </TabbedShowLayout>
          </Grid>
        </SimpleForm>
      </Card>
    </Box>
  );
};
export default ZoneEdit;
