import icon from '@mui/icons-material/Groups';
import list from './SharedCharacterList';
import edit from './SharedCharacterEdit';
import create from './SharedCharacterCreate';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  icon: icon,
  list: list,
  edit: edit,
  create: create,
  recordRepresentation: 'name',
  options: { label: 'Shared Characters' },
};
