import * as React from 'react';
import { useWatch, useFormContext, useFieldArray } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Button, Create, SimpleForm, TextInput, FileInput, FileField, Toolbar, SaveButton, useRefresh, useNotify, useArrayInput, useRedirect } from 'react-admin';
import { Alert, AlertTitle, Box, Card, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const GuildBankImport = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const onSuccess = () => {
    notify('Import Successful', { type: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    redirect('/guild-bank');
    refresh();
  };

  return (
    <Create
      component="div"
      mutationMode="pessimistic"
      mutationOptions={{ onSuccess }}
      record={{
        characterName: '',
        items: [],
      }}
    >
      <ImportForm />
    </Create>
  );
};

const ImportToolbar = () => {
  return (
    <Toolbar>
      <SaveButton type="button" label="Import" />
    </Toolbar>
  );
};
const ImportForm = notify => {
  const navigate = useNavigate();

  const ItemList = () => {
    const items = useWatch({ name: 'items' });

    if (items.length > 1) {
      let datagridColumns = [
        { field: 'itemName', headerName: 'Item', flex: 1 },
        { field: 'itemLocation', headerName: 'Location', minWidth: 150 },
      ];

      return <DataGrid columns={datagridColumns} rows={items} density="compact" pageSizeOptions={[5, 10, 20, 50, 100]} initialState={{ pagination: { paginationModel: { pageSize: 20 } } }} />;
    } else {
      return <ItemListEmpty />;
    }
  };

  const ItemListEmpty = () => {
    const { setValue } = useFormContext();
    const notify = useNotify();
    const fieldArray = useFieldArray({ name: 'items' });
    const { append } = useArrayInput(fieldArray);

    const fileValidator = file => {
      if (file.name.toLowerCase().includes('inventory.txt')) {
        return null;
      } else {
        notify(
          <Alert severity="error" color="error" variant="standard">
            <AlertTitle>Invalid File</AlertTitle>The selected file does not appear to be a Inventory export.
          </Alert>,
          { anchorOrigin: { vertical: 'top', horizontal: 'center' } }
        );
        return { code: 'invalid-file', message: 'File is not valid' };
      }
    };

    const fileParser = files => {
      console.log('Processing file');
      const reader = new FileReader();

      for (let z = 0; z < files.length; z++) {
        const file = files[z];

        reader.onloadend = function (e) {
          var fileContents = e.target.result.split('\r\n');
          var firstLine = fileContents.shift();

          if (firstLine === 'Location\tName\tID\tCount\tSlots') {
            for (let i = 0; i < fileContents.length; i++) {
              let item = fileContents[i].split('\t');
              const itemId = item[2];
              const itemName = item[1];
              const itemLocation = item[0];
              const itemSlots = item[4];

              if (itemName !== 'Empty' && itemSlots < 8) {
                append({ id: i, itemName: itemName, itemId: itemId, itemLocation: itemLocation });
              }
            }
          }

          setValue('characterName', file.name.split('-')[0] ? file.name.split('-')[0] : undefined);
        };

        reader.readAsText(file);
      }
    };

    return (
      <FileInput
        source="importFile"
        accept="text/plain"
        placeholder="Upload Inventory Output to Continue"
        options={{
          validator: fileValidator,
          onDropAccepted: fileParser,
        }}
        fullWidth
      >
        <FileField source="src" title="Inventory Output" />
      </FileInput>
    );
  };

  return (
    <Box maxWidth="50em">
      <Card>
        <SimpleForm toolbar={<ImportToolbar />}>
          <Grid container spacing={1}>
            <Grid item xs={10}></Grid>
            <Grid item xs={2}>
              <Button size="medium" alignIcon="left" label="Go back" onClick={() => navigate(-1)}>
                <ArrowBackIcon />
              </Button>
            </Grid>

            <Grid item xs={4}>
              <TextInput source="characterName" label="Character Name" variant="standard" fullWidth required />
            </Grid>
            <Grid item xs={12}>
              <ItemList />
            </Grid>
          </Grid>
        </SimpleForm>
      </Card>
    </Box>
  );
};
export default GuildBankImport;
