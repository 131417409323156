import _ from 'lodash';
import memberRoles from './memberRoles';

function MemberRole(props) {
  const memberRole = _.find(memberRoles, { id: props.id });

  return <span>{memberRole.name}</span>;
}

export default MemberRole;
