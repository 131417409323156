import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Button, Edit, SimpleForm, TextInput, ReferenceInput, useNotify, useRedirect, useRefresh, AutocompleteInput, ReferenceArrayInput, CheckboxGroupInput, SelectInput, required } from 'react-admin';
import { Box, Card, Grid } from '@mui/material';
import memberRoles from '../common/memberRoles';

const SharedCharacterEdit = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const onSuccess = () => {
    notify('Record updated successfully', { type: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    redirect('/shared-characters');
    refresh();
  };

  return (
    <Edit component="div" mutationMode="pessimistic" mutationOptions={{ onSuccess }}>
      <EditForm />
    </Edit>
  );
};

const EditForm = () => {
  const navigate = useNavigate();

  return (
    <Box maxWidth="50em">
      <Card>
        <SimpleForm>
          <Grid container spacing={1}>
            <Grid item xs={10}></Grid>
            <Grid item xs={2}>
              <Button size="medium" alignIcon="left" label="Go back" onClick={() => navigate(-1)}>
                <ArrowBackIcon />
              </Button>
            </Grid>

            <Grid item xs={4}>
              <TextInput source="name" label="Character Name" variant="standard" fullWidth required />
            </Grid>
            <Grid item xs={4}>
              <TextInput source="level" label="Level" variant="standard" fullWidth required />
            </Grid>
            <Grid item xs={4}>
              <ReferenceInput source="classId" reference="classes" sort={{ field: 'name', order: 'ASC' }}>
                <AutocompleteInput variant="standard" optionText="name" validate={required('Class Required')} />
              </ReferenceInput>
            </Grid>

            <Grid item xs={4}>
              <TextInput source="username" label="Username" variant="standard" fullWidth required />
            </Grid>
            <Grid item xs={4}>
              <TextInput source="password" label="Password" variant="standard" fullWidth required />
            </Grid>
            <Grid item xs={4}>
              <SelectInput source="useBy" label="Usable By" variant="standard" choices={memberRoles} sx={{ width: 250 }} required />
            </Grid>

            <Grid item xs={6}>
              <ReferenceInput source="parkZoneId" label="Parked Zone Name" reference="zones" sort={{ field: 'name', order: 'ASC' }}>
                <AutocompleteInput variant="standard" />
              </ReferenceInput>
            </Grid>
            <Grid item xs={6}>
              <ReferenceInput source="bindZoneId" label="Bind Point Zone Name" reference="zones" sort={{ field: 'name', order: 'ASC' }}>
                <AutocompleteInput variant="standard" />
              </ReferenceInput>
            </Grid>

            <Grid item xs={12}>
              <TextInput source="note" label="Note" variant="standard" fullWidth />
            </Grid>

            <Grid item xs={4}>
              <ReferenceArrayInput source="flags" reference="flags" sort={{ field: 'name', order: 'ASC' }}>
                <CheckboxGroupInput variant="standard" row={false} optionText="name" label="Flags" />
              </ReferenceArrayInput>
            </Grid>

            <Grid item xs={4}>
              <ReferenceArrayInput source="keys" reference="keys">
                <CheckboxGroupInput variant="standard" row={false} optionText="name" label="Keys" sort={{ field: 'name', order: 'ASC' }} />
              </ReferenceArrayInput>
            </Grid>
          </Grid>
        </SimpleForm>
      </Card>
    </Box>
  );
};
export default SharedCharacterEdit;
