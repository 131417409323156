import icon from '@mui/icons-material/Map';
import list from './ZoneList';
import edit from './ZoneEdit';
import show from './ZoneShow';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  icon: icon,
  list: list,
  edit: edit,
  show: show,
  recordRepresentation: 'name',
  options: { label: 'Zones' },
};
