import React from 'react';
import { List, Datagrid, SearchInput, TextField, NumberField, TopToolbar, BooleanField, ReferenceField } from 'react-admin';
import { TargetFilterSiderbar } from './TargetFilterSidebar';

const filters = [<SearchInput source="name" variant="standard" alwaysOn />];

const ListActions = () => <TopToolbar></TopToolbar>;

const TargetList = props => {
  return (
    <List {...props} filters={filters} title="Targets" perPage={25} actions={<ListActions />} aside={<TargetFilterSiderbar />} sort={{ field: 'name', order: 'ASC' }}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="name" label="Name" />
        <ReferenceField link={false} source="typeId" label="Type" reference="target-types" sort={{ field: 'type', order: 'ASC' }}>
          <TextField source="type" />
        </ReferenceField>
        <NumberField source="spawnTime" label="Spawn Time" />
        <NumberField source="spawnVariance" label="Spawn Variance" />
        <BooleanField source="showTimer" label="Show Timer" />
      </Datagrid>
    </List>
  );
};

export default TargetList;
