import React from 'react';
import { List, Datagrid, NumberField, TextField, ReferenceField, ReferenceArrayField, SingleFieldList, ChipField, FunctionField, TopToolbar, CreateButton, SelectInput, TextInput } from 'react-admin';
import { Box, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MemberRole from '../common/memberRole';

const filters = [
  <TextInput
    source="all"
    variant="standard"
    label="Character Name"
    alwaysOn
    sx={{ ml: 0 }}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />,
  <SelectInput
    alwaysOn={true}
    helperText={false}
    source="classId"
    variant="standard"
    choices={[
      { name: 'Bard', id: 8 },
      { name: 'Cleric', id: 2 },
      { name: 'Druid', id: 6 },
      { name: 'Enchanter', id: 14 },
      { name: 'Magician', id: 13 },
      { name: 'Monk', id: 7 },
      { name: 'Necromancer', id: 11 },
      { name: 'Paladin', id: 3 },
      { name: 'Ranger', id: 4 },
      { name: 'Rogue', id: 9 },
      { name: 'Shadow Knight', id: 5 },
      { name: 'Shaman', id: 10 },
      { name: 'Warrior', id: 1 },
      { name: 'Wizard', id: 12 },
    ]}
  />,
  <SelectInput
    alwaysOn={true}
    helperText={false}
    source="useBy"
    variant="standard"
    choices={[
      { name: 'Member', id: 2 },
      { name: 'Officer', id: 8 },
    ]}
  />,
];

const sort = { field: 'name', order: 'ASC' };

const ListActions = () => (
  <Box width="100%">
    <TopToolbar>
      <CreateButton label="New Shared Character" size="large" />
    </TopToolbar>
  </Box>
);

const SharedCharacterList = props => {
  return (
    <List {...props} filters={filters} sort={sort} title="Shared Characters" actions={<ListActions />} perPage={25}>
      <Datagrid rowClick="edit" bulkActionButtons={false} size="small">
        <TextField source="name" label="Name" />
        <NumberField source="level" label="Level" />
        <ReferenceField source="classId" reference="classes">
          <TextField source="name" />
        </ReferenceField>
        <FunctionField label="Usable By" render={record => <MemberRole id={record.useBy} />} />
        <ReferenceArrayField label="Flags" reference="flags" source="flags">
          <SingleFieldList linkType={false}>
            <ChipField
              source="name"
              size="small"
              sx={{
                '&.RaChipField-chip': {
                  m: 0,
                  mx: '2px',
                },
              }}
            />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField label="Keys" reference="keys" source="keys">
          <SingleFieldList linkType={false}>
            <ChipField
              source="name"
              size="small"
              sx={{
                '&.RaChipField-chip': {
                  m: 0,
                  mx: '2px',
                },
              }}
            />
          </SingleFieldList>
        </ReferenceArrayField>
      </Datagrid>
    </List>
  );
};

export default SharedCharacterList;
