import * as React from 'react';
import ZoneTargetTable from './ZoneTargetTable';

import { useRecordContext, EditButton, Show, SimpleShowLayout, TextField, Labeled, TabbedShowLayout } from 'react-admin';
import { Box, Card, Chip, Grid, Typography } from '@mui/material';

const ZoneShow = props => {
  return <ShowForm />;
};

const AliasesField = () => {
  const record = useRecordContext();
  return (
    <>
      {record.aliases.map(alias => (
        <Chip variant="standard" size="medium" sx={{ mr: 1, mt: -1 }} key={alias} label={alias} />
      ))}
    </>
  );
};

const ShowForm = () => {
  return (
    <Box maxWidth="50em">
      <Show component="div" actions={<></>} sx={{ mt: 2 }} emptyWhileLoading>
        <Card>
          <SimpleShowLayout>
            <Grid container spacing={1}>
              <Grid item xs={11}>
                <Typography variant="h6" gutterBottom align="left">
                  Details
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <EditButton />
              </Grid>
              <Grid item xs={8}>
                <Labeled>
                  <TextField source="name" label="Zone Name" variant="standard" fullWidth />
                </Labeled>
              </Grid>
              <Grid item xs={4}>
                <Labeled>
                  <TextField source="nameShort" label="Short Name" variant="standard" fullWidth />
                </Labeled>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom align="left">
                  Aliases
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <AliasesField />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <TabbedShowLayout>
                <TabbedShowLayout.Tab label="Zone Targets">
                  <ZoneTargetTable />
                </TabbedShowLayout.Tab>
              </TabbedShowLayout>
            </Grid>
          </SimpleShowLayout>
        </Card>
      </Show>
    </Box>
  );
};
export default ZoneShow;
