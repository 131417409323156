import * as React from 'react';

import { EditButton, Show, NumberField, SimpleShowLayout, TextField, Labeled, ReferenceField, TabbedShowLayout, BooleanField, useRecordContext } from 'react-admin';
import { Box, Card, Chip, Grid, Typography } from '@mui/material';
import TargetTODTable from './TargetTODTable';
import TargetTOSTable from './TargetTOSTable';

const TargetShow = props => {
  return <ShowForm />;
};

const AliasesField = () => {
  const record = useRecordContext();
  return (
    <>
      {record.aliases.map(alias => (
        <Chip variant="standard" size="medium" sx={{ mr: 1, mt: -1 }} key={alias} label={alias} />
      ))}
    </>
  );
};

const ShowForm = () => {
  return (
    <Box maxWidth="50em">
      <Show component="div" actions={<></>} sx={{ mt: 2 }} emptyWhileLoading>
        <Card>
          <SimpleShowLayout>
            <Grid container spacing={1}>
              <Grid item xs={11}>
                <Typography variant="h6" gutterBottom align="left">
                  Details
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <EditButton />
              </Grid>
              <Grid item xs={6}>
                <Labeled>
                  <TextField source="name" label="Target Name" variant="standard" fullWidth />
                </Labeled>
              </Grid>
              <Grid item xs={6}>
                <Labeled>
                  <ReferenceField link="show" source="zoneId" label="Zone Name" reference="zones" sort={{ field: 'name', order: 'ASC' }} />
                </Labeled>
              </Grid>
              <Grid item xs={6}>
                <Labeled>
                  <ReferenceField link={false} source="typeId" label="Type" reference="target-types" sort={{ field: 'type', order: 'ASC' }}>
                    <TextField source="type" />
                  </ReferenceField>
                </Labeled>
              </Grid>
              <Grid item xs={3}>
                <Labeled>
                  <NumberField source="spawnTime" variant="standard" label="Spawn Time (Hours)" />
                </Labeled>
              </Grid>
              <Grid item xs={3}>
                <Labeled>
                  <NumberField source="spawnVariance" variant="standard" label="Spawn Time Variance (Hours)" />
                </Labeled>
              </Grid>
              <Grid item xs={3}>
                <Labeled>
                  <BooleanField label="Show Timer" source="showTimer" />
                </Labeled>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom align="left">
                  Aliases
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <AliasesField />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <TabbedShowLayout>
                <TabbedShowLayout.Tab label="Times of Death">
                  <TargetTODTable />
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="Times of Spawn">
                  <TargetTOSTable />
                </TabbedShowLayout.Tab>
              </TabbedShowLayout>
            </Grid>
          </SimpleShowLayout>
        </Card>
      </Show>
    </Box>
  );
};
export default TargetShow;
