import React from 'react';
import { List, Datagrid, TopToolbar, CreateButton, TextField, SearchInput } from 'react-admin';
import { Box } from '@mui/material';

const sort = { field: 'itemName', order: 'ASC' };

const filters = [<SearchInput source="itemName" variant="standard" alwaysOn />];

const ListActions = () => (
  <Box width="100%">
    <TopToolbar>
      <CreateButton label="Import Items" size="large" />
    </TopToolbar>
  </Box>
);

const GuildBankList = props => {
  return (
    <List {...props} filters={filters} sort={sort} title="Guild Bank" perPage={25} actions={<ListActions />} emptyWhileLoading>
      <Datagrid rowClick="expand" bulkActionButtons={false}>
        <TextField source="itemName" label="Item" />
        <TextField source="characterName" label="Character" />
        <TextField source="itemLocation" label="Slot" />
      </Datagrid>
    </List>
  );
};

export default GuildBankList;
