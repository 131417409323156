import icon from '@mui/icons-material/Pets';
import list from './TargetList';
import edit from './TargetEdit';
import view from './TargetShow';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  icon: icon,
  list: list,
  edit: edit,
  show: view,
  recordRepresentation: 'name',
  options: { label: 'Targets' },
};
