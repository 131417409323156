import React from 'react';
import { List, Datagrid, SearchInput, TextField, TopToolbar } from 'react-admin';

const filters = [<SearchInput source="name" variant="standard" alwaysOn />];

const ListActions = () => <TopToolbar></TopToolbar>;

const ZoneList = props => {
  return (
    <List {...props} filters={filters} title="Zones" perPage={25} actions={<ListActions />} sort={{ field: 'name', order: 'ASC' }}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="name" label="Name" />
        <TextField source="nameShort" label="Short Name" />
      </Datagrid>
    </List>
  );
};

export default ZoneList;
