import * as React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useRecordContext, useGetManyReference, DeleteWithConfirmButton, DateField, EditButton, Loading } from 'react-admin';
import { TableCellRight } from '../common/TableCellRight';

const TODTable = () => {
  const record = useRecordContext();

  // fetch all tods related to the current target
  const { data, isLoading, error } = useGetManyReference('tod', {
    target: 'targetId',
    id: record.id,
    pagination: { page: 1, perPage: 10 },
    sort: { field: 'tod', order: 'DESC' },
  });

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <p>Error loading Time of Death data</p>;
  }

  if (data.length === 0) {
    return <p>No Time of Death data found</p>;
  }

  return (
    <Table size="small" sx={{ maxWidth: '100%' }}>
      <TableHead>
        <TableRow>
          <TableCell style={{ width: '50%', fontWeight: 'bold' }}>Time of Death</TableCell>
          <TableCell style={{ width: '25%', fontWeight: 'bold' }}>Entered By</TableCell>
          <TableCellRight style={{ width: '25%' }}></TableCellRight>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(tod => (
          <TableRow key={tod.id}>
            <TableCell>
              <DateField record={tod} source="tod" showTime options={{ day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'America/New_York' }} />
            </TableCell>
            <TableCell>{tod.updatedBy_displayName}</TableCell>
            <TableCellRight>
              <EditButton disabled />
              <DeleteWithConfirmButton resource="tod" record={tod} label="Remove" confirmTitle="Remove Time of Death" confirmContent="This action is unreverseable. Are you sure?" redirect={false} />
            </TableCellRight>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default TODTable;
